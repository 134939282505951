import LoaderSpinner from "react-loader-spinner";
import styled from "styled-components";

const LoaderWrapper = styled.div`
  position: fixed;
  background: #f1f1f1cf;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Loader = () => {
  return (
    <LoaderWrapper>
      <LoaderSpinner type="Oval" color="#21254f" height={100} width={100} />
    </LoaderWrapper>
  );
};
