import React from "react";
import styled from "styled-components";

const TextBoxWrapper = styled.div`
  display: ${(props) => (props.fullWidth ? "block" : "inline-block")};

  .label {
    display: block;
    min-height: 20px;
    margin-right: 16px;
    font-size: 14px;
    color: #8b8b8b;
  }
`;

const TextInput = styled.input`
  color: #C1C1C1;
  border: none;
  border-bottom: 1px solid #c1c1c1;
  background: #f1f1f1;
  padding: 6px 8px;
  font-size: 16px;
  font-weight: 500;
  outline: 0;
  transition-duration: 0.5s;
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #c1c1c1;
  }
  :-ms-input-placeholder {
     color: #c1c1c1;
  }

  :focus {
    color: #434343;
    background: #E8E8E8;
    border-color: #174FE9;
  }
`;

const TextAreaInput = styled.textarea`
  color: #C1C1C1;
  border: none;
  border-bottom: 1px solid #c1c1c1;
  background: #f1f1f1;
  padding: 6px 8px;
  font-size: 16px;
  font-weight: 500;
  outline: 0;
  transition-duration: 0.5s;
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #c1c1c1;
  }
  :-ms-input-placeholder {
     color: #c1c1c1;
  }

  :focus {
    color: #434343;
    background: #E8E8E8;
    border-color: #174FE9;
  }
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;

  input {
    flex: 1;
  }

  .suffix {
    font-size: 16;
    color: #174fe9;
    border-bottom: 1px solid #c1c1c1;
  background: #f1f1f1;
  padding: 6px 8px;
  }
`

export const TextBox = (props) => {
  const [value, setValue] = React.useState(props.value);

  React.useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const onChange = (e) => {
    setValue(e.target.value);
    if (props.onChange) props.onChange(e.target.value);
  };

  const onBlur = (e) => {
    if (props.onChange) props.onChange(value);
  };

  return (
    <TextBoxWrapper className="tomb__textbox" fullWidth={props.fullWidth} horizontal={props.horizontal}>
      {props.multiline ? (
        <TextAreaInput
          required={props.required}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={`${props.placeholder} ${props.required ? "*" : ""}`}
          fullWidth={props.fullWidth}
          value={value || ""}
          autoComplete="off"
        ></TextAreaInput>
      ) : (
        <>
          {!props.noLabel && (
            <span className="label">{props.label}</span>
          )}
          <InputWrapper>
            <TextInput
              type={props.type || "text"}
              required={props.required}
              value={value || ""}
              onChange={onChange}
              onBlur={onBlur}
              placeholder={`${props.placeholder} ${props.required ? "*" : ""}`}
              fullWidth={props.fullWidth}
              autoComplete="off"
            />
            {props.suffix && <span className="suffix">{props.suffix}</span>}
          </InputWrapper>
        </>
      )
      }
    </TextBoxWrapper >
  );
};
