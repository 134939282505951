
export const IconArrowCircleRecyle = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
    <g id="arrow-circle-rycycle" transform="translate(-3.106 -0.166)">
      <g id="Group_14" data-name="Group 14" transform="translate(3.106 0.166)">
        <path id="Path_21" data-name="Path 21" d="M33.392,11.735l-1.266-.374c.006-.117.015-.232.015-.348a6.482,6.482,0,0,0-5.06-6.4l-.23,1.2a5.271,5.271,0,0,1,4.112,5.2v.005l-1.145-.338a.514.514,0,0,0-.187.686l1.04,1.939a.482.482,0,0,0,.663.2l1.871-1.08a.514.514,0,0,0,.186-.686Z" transform="translate(-19.454 -3.232)" fill="#8b8b8b" fillRule="evenodd" />
        <path id="Path_22" data-name="Path 22" d="M4.283,7.693a5.339,5.339,0,0,1,2.9-4.779L7.724,4.37a.518.518,0,0,0,.693-.276l.942-2.188a.553.553,0,0,0-.267-.719L6.981.213a.517.517,0,0,0-.693.275l.476,1.287A6.566,6.566,0,0,0,3.106,7.694,6.692,6.692,0,0,0,3.461,9.84l1.11-.4a5.481,5.481,0,0,1-.289-1.746Z" transform="translate(-3.106 -0.166)" fill="#8b8b8b" fillRule="evenodd" />
        <path id="Path_23" data-name="Path 23" d="M13.618,36.2a4.931,4.931,0,0,1-2.6-.713l.991-1.044a.519.519,0,0,0-.53-.523H9.17a.534.534,0,0,0-.522.549l.016,2.4A.533.533,0,0,0,9.2,37.4l.982-1.033a6.1,6.1,0,0,0,3.441,1.045,6.211,6.211,0,0,0,4.666-2.156l-.873-.818A5.048,5.048,0,0,1,13.618,36.2Z" transform="translate(-6.921 -23.416)" fill="#8b8b8b" fillRule="evenodd" />
      </g>
    </g>
  </svg>
)

export const IconArrowDownFill = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="8.423" height="5" viewBox="0 0 8.423 5">
    <g id="arrow-down-fill" transform="translate(-11.998 -16)">
      <path id="Path_20" data-name="Path 20" d="M16.209,21a1.347,1.347,0,0,1-.933-.356l-2.954-2.833a.975.975,0,0,1-.182-1.228A1.278,1.278,0,0,1,13.255,16h5.909a1.278,1.278,0,0,1,1.116.583.975.975,0,0,1-.182,1.228l-2.954,2.833A1.347,1.347,0,0,1,16.209,21Z" fill="#174fe9" />
    </g>
  </svg>
)

export const IconEmail = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="11.128" viewBox="0 0 14 11.128">
    <path id="Path_29" data-name="Path 29" d="M9.793,5.4H5.407a19.684,19.684,0,0,0-2.4.084,2.669,2.669,0,0,0-1.622.7,2.669,2.669,0,0,0-.7,1.622,19.684,19.684,0,0,0-.084,2.4v1.515a19.685,19.685,0,0,0,.084,2.4,2.669,2.669,0,0,0,.7,1.622,2.669,2.669,0,0,0,1.622.7,19.7,19.7,0,0,0,2.4.084H9.793a19.7,19.7,0,0,0,2.4-.084,2.669,2.669,0,0,0,1.622-.7,2.669,2.669,0,0,0,.7-1.622,19.7,19.7,0,0,0,.084-2.4V10.207a19.7,19.7,0,0,0-.084-2.4,2.669,2.669,0,0,0-.7-1.622,2.669,2.669,0,0,0-1.622-.7A19.685,19.685,0,0,0,9.793,5.4Zm-6.2,2.962a.538.538,0,0,0-.6.9l1.943,1.3a16.623,16.623,0,0,0,1.41.882,2.659,2.659,0,0,0,1.253.35,2.659,2.659,0,0,0,1.253-.35,16.628,16.628,0,0,0,1.41-.882l1.943-1.3a.538.538,0,0,0-.6-.9L9.691,9.641c-.589.393-1,.663-1.334.839a1.6,1.6,0,0,1-.756.229,1.6,1.6,0,0,1-.756-.229C6.5,10.3,6.1,10.034,5.509,9.641Z" transform="translate(-0.6 -5.4)" fill="#174fe9" fillRule="evenodd" />
  </svg>
)

export const IconGrid = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="grid" transform="translate(-0.281 -0.281)">
      <g id="Group_13" data-name="Group 13" transform="translate(0.281 0.281)">
        <path id="Path_16" data-name="Path 16" d="M9.5,11.424H2.21A1.929,1.929,0,0,1,.281,9.5V2.21A1.929,1.929,0,0,1,2.21.281H9.5A1.929,1.929,0,0,1,11.424,2.21V9.5A1.929,1.929,0,0,1,9.5,11.424Z" transform="translate(-0.281 -0.281)" fill="#174fe9" />
        <path id="Path_17" data-name="Path 17" d="M11.6,11.424H4.319A1.929,1.929,0,0,1,2.391,9.5V2.21A1.929,1.929,0,0,1,4.319.281H11.6A1.929,1.929,0,0,1,13.533,2.21V9.5A1.929,1.929,0,0,1,11.6,11.424Z" transform="translate(10.467 -0.281)" fill="#174fe9" />
        <path id="Path_18" data-name="Path 18" d="M9.5,13.533H2.21A1.929,1.929,0,0,1,.281,11.6V4.319A1.929,1.929,0,0,1,2.21,2.391H9.5a1.929,1.929,0,0,1,1.929,1.929V11.6A1.929,1.929,0,0,1,9.5,13.533Z" transform="translate(-0.281 10.467)" fill="#174fe9" />
        <path id="Path_19" data-name="Path 19" d="M11.6,13.533H4.319A1.929,1.929,0,0,1,2.391,11.6V4.319A1.929,1.929,0,0,1,4.319,2.391H11.6a1.929,1.929,0,0,1,1.929,1.929V11.6A1.929,1.929,0,0,1,11.6,13.533Z" transform="translate(10.467 10.467)" fill="#174fe9" />
      </g>
    </g>
  </svg>
)

export const IconList = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23.999" viewBox="0 0 24 23.999">
    <g id="list" transform="translate(-8.425 -8.425)">
      <path id="Path_13" data-name="Path 13" d="M8.429,13.131h0a.839.839,0,0,0,.831.838H31.585a.839.839,0,0,0,.835-.839h0V9.263a.839.839,0,0,0-.839-.838H9.264a.839.839,0,0,0-.839.839c0,.014,0,.028,0,.043v3.824Z" fill="#8b8b8b" />
      <path id="Path_14" data-name="Path 14" d="M31.585,20.4H9.264a.839.839,0,0,0-.839.839c0,.014,0,.028,0,.043v3.824h0a.839.839,0,0,0,.831.838H31.586a.839.839,0,0,0,.835-.839h0V21.24A.84.84,0,0,0,31.585,20.4Z" transform="translate(0 -2.749)" fill="#8b8b8b" />
      <path id="Path_15" data-name="Path 15" d="M31.585,32.378H9.264a.839.839,0,0,0-.839.839c0,.014,0,.028,0,.043v3.824h0a.839.839,0,0,0,.831.838H31.586a.839.839,0,0,0,.835-.839h0V33.215A.84.84,0,0,0,31.585,32.378Z" transform="translate(0 -5.499)" fill="#8b8b8b" />
    </g>
  </svg>
)

export const IconSearch = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
    <g id="search" transform="translate(-3.998 -4)">
      <path id="Path_24" data-name="Path 24" d="M14.026,13.029,17.792,16.8a.706.706,0,0,1-1,1l-3.766-3.766a5.6,5.6,0,1,1,1-1ZM9.6,13.8A4.2,4.2,0,1,0,5.4,9.6,4.2,4.2,0,0,0,9.6,13.8Z" transform="translate(0 0)" fill="#f5f5f5" fillRule="evenodd" />
    </g>
  </svg>
)

export const IconWeb = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
    <path id="Path_28" data-name="Path 28" d="M13.521,11.211l-2.311,2.311A1.53,1.53,0,0,1,10.069,14a1.574,1.574,0,0,1-1.155-.479L6.6,11.211a1.545,1.545,0,0,1-.465-1.06,1.594,1.594,0,0,1,.355-1.1L9.42,11.99a.911.911,0,0,0,.649.26.865.865,0,0,0,.636-.26l1.285-1.285a.865.865,0,0,0,.26-.636.911.911,0,0,0-.26-.649L9.051,6.494a1.594,1.594,0,0,1,1.1-.355,1.545,1.545,0,0,1,1.06.465l2.311,2.311A1.574,1.574,0,0,1,14,10.069,1.53,1.53,0,0,1,13.521,11.211ZM4.662,4.662a.892.892,0,0,1,.615-.273.773.773,0,0,1,.6.232L9.393,8.148a.741.741,0,0,1,.232.595.9.9,0,0,1-.273.622.866.866,0,0,1-.615.267.819.819,0,0,1-.6-.226L4.621,5.879a.773.773,0,0,1-.232-.6A.892.892,0,0,1,4.662,4.662ZM4.58,2.023a.843.843,0,0,0-.636-.273.887.887,0,0,0-.649.273L2.023,3.295a.887.887,0,0,0-.273.649.843.843,0,0,0,.273.636L4.949,7.506a1.594,1.594,0,0,1-1.1.355A1.545,1.545,0,0,1,2.789,7.4L.479,5.086A1.574,1.574,0,0,1,0,3.931,1.574,1.574,0,0,1,.479,2.775L2.789.479A1.53,1.53,0,0,1,3.931,0,1.574,1.574,0,0,1,5.086.479L7.4,2.775a1.6,1.6,0,0,1,.465,1.073,1.594,1.594,0,0,1-.355,1.1Z" fill="#174fe9" />
  </svg>
)

export const SvgLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="193.226" height="15.933" viewBox="0 0 193.226 15.933">
    <g id="Group_12" data-name="Group 12" transform="translate(1014.849 -1462.016)">
      <path id="Path_2" data-name="Path 2" d="M-1009.546,1466.419a2.294,2.294,0,0,1,1.838-.832c.15,0,.64-.02.64-.269a.177.177,0,0,0-.051-.138.75.75,0,0,0-.51-.1h-6.687a.682.682,0,0,0-.483.1.177.177,0,0,0-.051.138c0,.266.634.269.64.269a2.3,2.3,0,0,1,1.836.83,2.732,2.732,0,0,1,.2,1.21v9.606a3.772,3.772,0,0,1-.2,1.472,2.293,2.293,0,0,1-1.838.833c-.006,0-.64,0-.64.269a.178.178,0,0,0,.051.138.68.68,0,0,0,.483.1h6.687a.749.749,0,0,0,.51-.1.178.178,0,0,0,.051-.138c0-.249-.488-.269-.636-.269h0a2.3,2.3,0,0,1-1.841-.832,3.248,3.248,0,0,1-.2-1.473v-9.606A2.737,2.737,0,0,1-1009.546,1466.419Z" transform="translate(0 -2.102)" fill="#020202" />
      <path id="Path_3" data-name="Path 3" d="M-955.993,1465.076h-4.543c-.327,0-.876.03-.876.234,0,.121.158.188,1.267.42.181.038.368.077.406.093.712.221.919.45.919,1.541v9.985l-10.7-9.609c-.22-.2-.436-.406-.664-.627-1.022-.989-2.079-2.012-3.6-2.012h-2.978c-.431,0-.876,0-.876.234,0,.123.157.155.578.239.1.021.214.044.332.07,1.686.378,1.837.983,1.837,2.617v8.677c0,1.444-.206,1.716-.916,1.9l-.76.2c-.263,0-.917.09-.917.286,0,.24.452.26.876.26h4.544c.431,0,.876,0,.876-.234,0-.205-.582-.309-.917-.311l-.76-.2c-.78-.212-.916-.531-.916-2.13v-9.728l14.033,12.685a2.355,2.355,0,0,0,1.315.383c.479,0,.722-.182.722-.543v-11.551c0-1.921.14-2.006,1.657-2.334.1-.023.22-.041.333-.058.363-.056.6-.092.6-.252C-955.117,1465.093-955.787,1465.076-955.993,1465.076Z" transform="translate(-25.563 -2.102)" fill="#020202" />
      <path id="Path_4" data-name="Path 4" d="M-869.6,1465.076h-6.762l-.3.108.013.068c.052.272.456.388.968.535.676.193,1.442.413,1.442,1.022a2.522,2.522,0,0,1-.459,1.214l-5.985,9.509-5.619-8.933a4.894,4.894,0,0,1-.983-2.053c0-.459.415-.747,1.432-.995l.026-.012a.281.281,0,0,0,.141-.252v-.066l-.065-.014a6.852,6.852,0,0,0-1.406-.133h-5.032c-.193,0-1.156.01-1.156.213,0,.166.2.233.573.355a5.861,5.861,0,0,1,3.45,2.65l6.578,10.431c.56.9.9,1.323,1.3,1.323.331,0,.7-.37,1.328-1.322l6.5-10.274c.907-1.433,2.087-2.9,3.981-2.9a.73.73,0,0,0,.411-.154l.024-.024v-.034C-869.2,1465.146-869.386,1465.1-869.6,1465.076Z" transform="translate(-83.466 -2.102)" fill="#020202" />
      <path id="Path_5" data-name="Path 5" d="M-787.174,1476.215c-.222,0-.249.218-.265.348a.583.583,0,0,1-.024.129,4.118,4.118,0,0,1-.646,1.294c-.789.947-3.188.947-4.476.947h-1.082c-1.09,0-2.163-.026-4.138-.158-.058,0-.121-.006-.188-.007-.432-.011-.738-.045-.738-.385v-5.8h6.847a1.85,1.85,0,0,1,1.435,1.2l.008.026a.825.825,0,0,1,.07.386c0,.15,0,.32.214.32.267,0,.267-.366.267-.689v-3.642c0-.311,0-.663-.241-.663-.194,0-.228.21-.268.454a1.523,1.523,0,0,1-1.474,1.473h-6.859v-4.589a.548.548,0,0,1,.261-.422,32.876,32.876,0,0,1,3.932-.182h1.821c1.723,0,3.385.067,3.874.556a1.582,1.582,0,0,1,.353.584,3,3,0,0,1,.257,1.057c.017.135.061.495.293.495.41,0,.558-3.113.558-3.144v-.291c0-.309-.023-.425-.267-.425-.041,0-.081,0-.14.008-.168.014-.518.044-1.628.044H-803.09c-.287,0-.769.024-.769.188s.293.242,1.068.407a4.442,4.442,0,0,1,.672.171c.794.333.979.582.979,2.273v8.9c0,1.932-.188,1.983-1.65,2.378l-.085.023a1.726,1.726,0,0,1-.23.03c-.312.03-.7.066-.7.313,0,.22.414.241,1.508.241h13.514c1.687,0,1.773-1.94,1.83-3.223.007-.148.013-.289.021-.418v-.016l-.005-.015A.208.208,0,0,0-787.174,1476.215Z" transform="translate(-144.938 -2.102)" fill="#020202" />
      <path id="Path_6" data-name="Path 6" d="M-715.792,1465.076h-4.544c-.327,0-.876.03-.876.234,0,.121.158.188,1.267.42.181.038.368.077.407.093.712.221.919.45.919,1.541v9.985l-10.7-9.609c-.22-.2-.436-.406-.664-.627-1.022-.989-2.079-2.012-3.6-2.012h-2.978c-.43,0-.876,0-.876.234,0,.123.157.155.578.239.1.021.215.044.332.07,1.687.378,1.837.983,1.837,2.617v8.677c0,1.444-.206,1.716-.916,1.9l-.76.2c-.263,0-.917.09-.917.286,0,.24.452.26.876.26h4.544c.43,0,.876,0,.876-.234,0-.205-.582-.309-.917-.311l-.76-.2c-.779-.212-.917-.531-.917-2.13v-9.728l14.033,12.685a2.355,2.355,0,0,0,1.315.383c.479,0,.722-.182.722-.543v-11.551c0-1.921.14-2.006,1.656-2.334.1-.023.22-.041.334-.058.363-.056.6-.092.6-.252C-714.916,1465.093-715.586,1465.076-715.792,1465.076Z" transform="translate(-190.568 -2.102)" fill="#020202" />
      <path id="Path_7" data-name="Path 7" d="M-635.372,1465.076c-.281,0-.782.035-1.362.076-.712.05-1.519.107-2.071.107h-10.326c-.552,0-1.359-.057-2.071-.107-.58-.041-1.081-.076-1.362-.076-.339,0-.58,2.586-.58,3.41v.577l0,.025a.244.244,0,0,0,.235.161h.026l.022-.016a3.275,3.275,0,0,0,.6-1.079,7.048,7.048,0,0,1,.361-.752c.474-.774.9-.9,1.948-.9h4.767v10.612a3.877,3.877,0,0,1-.2,1.611,2.265,2.265,0,0,1-1.816.823c-.007,0-.632,0-.632.266a.175.175,0,0,0,.05.136.671.671,0,0,0,.477.1h6.6a.738.738,0,0,0,.5-.1.175.175,0,0,0,.05-.136c0-.246-.482-.266-.628-.266h0a2.271,2.271,0,0,1-1.817-.819,2.708,2.708,0,0,1-.2-1.2V1466.5h4.794c1.046,0,1.474.131,1.948.9a7.126,7.126,0,0,1,.361.752,3.288,3.288,0,0,0,.6,1.079l.022.016h.027a.244.244,0,0,0,.235-.161l0-.6C-634.792,1467.662-635.033,1465.076-635.372,1465.076Z" transform="translate(-248.47 -2.102)" fill="#020202" />
      <path id="Path_8" data-name="Path 8" d="M-565.4,1466.692a12.065,12.065,0,0,0-6.239-1.616,11.955,11.955,0,0,0-8.775,3.483,6.493,6.493,0,0,0-1.749,4.3c0,4.9,5.558,7.19,9.314,7.19a13.011,13.011,0,0,0,8.247-2.733,6.484,6.484,0,0,0,2.6-5.025A6.788,6.788,0,0,0-565.4,1466.692Zm-6.14,12.164c-3.91,0-8.1-2.648-8.1-6.591a5.89,5.89,0,0,1,2.074-4.314,7.177,7.177,0,0,1,4.863-1.734,9.562,9.562,0,0,1,3.87.832c2.068.959,4.277,2.995,4.277,5.833C-564.555,1476.573-568.181,1478.857-571.54,1478.857Z" transform="translate(-297.231 -2.102)" fill="#020202" />
      <g id="Group_11" data-name="Group 11" transform="translate(-835.528 1462.016)">
        <path id="Path_9" data-name="Path 9" d="M-438.846,1465.876a1.079,1.079,0,0,0-1.152-.826h-1.784c-.394,0-.3.39.03.39.7,0,.973.154,1.362,1.052l3.339,7.352V1469.8Z" transform="translate(442.041 -1464.1)" fill="#020202" />
        <path id="Path_10" data-name="Path 10" d="M-411.771,1465.876a1.079,1.079,0,0,1,1.152-.826h1.783c.394,0,.3.39-.03.39-.7,0-.973.154-1.362,1.052l-3.339,7.352V1469.8Z" transform="translate(422.481 -1464.1)" fill="#020202" />
        <path id="Path_11" data-name="Path 11" d="M-426.271,1463.456a1.5,1.5,0,0,0-.121-.7,1.434,1.434,0,0,0-1.107-.5c-.182,0-.334-.03-.334-.121,0-.076.121-.121.3-.121.621,0,1.274.03,2.2.03.94,0,1.592-.03,2.228-.03.182,0,.3.045.3.121,0,.091-.152.121-.334.121a1.465,1.465,0,0,0-1.122.5,1.5,1.5,0,0,0-.121.7V1476.4a1.656,1.656,0,0,0,.121.8,1.464,1.464,0,0,0,1.122.5c.182,0,.334.03.334.121,0,.076-.121.121-.3.121-.621,0-1.289-.03-2.213-.03-.94,0-1.592.03-2.213.03-.182,0-.3-.045-.3-.121,0-.091.152-.121.334-.121a1.433,1.433,0,0,0,1.107-.5,1.657,1.657,0,0,0,.121-.8Z" transform="translate(432.28 -1462.016)" fill="#174fe9" />
      </g>
      <path id="Path_12" data-name="Path 12" d="M-488.793,1479.625c-.046-.007-.1-.022-.161-.037a5.4,5.4,0,0,1-2.261-1.677,34.589,34.589,0,0,1-2.838-5.615l-.245-.565c.19-.017.348-.034.462-.048a3.381,3.381,0,0,0,.5-.1c1.207-.336,3.274-1.189,3.274-3.09,0-3.419-5.359-3.419-7.651-3.419-.855,0-1.725.019-2.567.034s-1.691.034-2.53.034h-1.932c-.279,0-.5-.006-.6.094a.149.149,0,0,0-.047.122c0,.034.025.075.081.128a1.322,1.322,0,0,0,.485.106c.016,0,.088.016.138.022.085.016.138.025.147.025l.138.031a4.657,4.657,0,0,1,.532.15c.57.229.773.379.817,1.174v10.428a3.427,3.427,0,0,1-.178,1.437,2.015,2.015,0,0,1-1.618.736c-.006,0-.563,0-.563.235a.155.155,0,0,0,.044.122.586.586,0,0,0,.426.094h5.895a.644.644,0,0,0,.448-.094.155.155,0,0,0,.044-.122c0-.219-.429-.235-.56-.235a2.873,2.873,0,0,1-.939-.2,1.315,1.315,0,0,1-.682-.532,3.455,3.455,0,0,1-.178-1.44v-5.61h4.5c.007.014.013.022.02.038,1.435,3.517,3.926,8.194,6.266,8.194h.932c.507,0,.7.035.7-.135C-488.507,1479.913-488.449,1479.677-488.793,1479.625Zm-12.124-13a.457.457,0,0,1,.488-.441c.313,0,.711-.022,1.174-.047.62-.031,1.321-.069,2.054-.069,3.156,0,4.627.755,4.627,2.382,0,1.747-2.025,2.382-3.387,2.382l-4.956-.047Z" transform="translate(-349.954 -2.101)" fill="#020202" />
    </g>
  </svg>
)

export const IconArrorRight = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="11" viewBox="0 0 14 11">
    <g id="arrow" transform="translate(-5.12 -20.482)">
      <g id="Group_35" data-name="Group 35" transform="translate(5.12 20.482)">
        <path id="Path_36" data-name="Path 36" d="M12.913,20.775a1,1,0,0,1,1.414,0l4.5,4.5a1,1,0,0,1,0,1.414l-4.5,4.5a1,1,0,1,1-1.414-1.414l2.707-2.793H6.12a1,1,0,1,1,0-2h9.5l-2.707-2.793A1,1,0,0,1,12.913,20.775Z" transform="translate(-5.12 -20.482)" fill="#f5f5f5" />
      </g>
    </g>
  </svg>
)

export const IconBoat = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="31.439" height="32" viewBox="0 0 31.439 32">
    <g id="boat" transform="translate(-3 -2.25)">
      <path id="Path_46" data-name="Path 46" d="M31.457,43.011a.479.479,0,0,0-.251-.421,19.954,19.954,0,0,1-4.395-3.369.705.705,0,0,0-.893-.106,10.32,10.32,0,0,1-11.365,0,.708.708,0,0,0-.9.109,19.006,19.006,0,0,1-4.381,3.351.5.5,0,0,0-.273.4.472.472,0,0,0,.556.5,16,16,0,0,0,4.225-1.672.611.611,0,0,1,.561,0,12.993,12.993,0,0,0,11.777,0,.619.619,0,0,1,.568,0,16.318,16.318,0,0,0,4.211,1.67.472.472,0,0,0,.561-.471Z" transform="translate(-1.508 -9.241)" fill="#65d9d9" />
      <path id="Path_47" data-name="Path 47" d="M34.209,17.893a2.363,2.363,0,0,0-1.467-1.288l-1.671-.665V10.11A4.491,4.491,0,0,0,26.58,5.619H24.334V5.057A2.807,2.807,0,0,0,21.527,2.25H15.913a2.807,2.807,0,0,0-2.807,2.807v.561H10.86A4.491,4.491,0,0,0,6.369,10.11v5.835L4.714,16.6A2.379,2.379,0,0,0,3.229,17.9a2.5,2.5,0,0,0-.091,1.857l.007.021L6.42,28.159A1.637,1.637,0,0,0,7.953,29.2c.035,0,.07,0,.107,0a8.705,8.705,0,0,0,5.095-2.218A8.584,8.584,0,0,0,18.72,29.2a8.55,8.55,0,0,0,5.554-2.225,8.692,8.692,0,0,0,5.1,2.221,1.639,1.639,0,0,0,1.64-1.034l3.274-8.351A2.522,2.522,0,0,0,34.209,17.893ZM19.632,11.388l-.08-.028a2.774,2.774,0,0,0-1.665,0l-.041.013L9,14.9a.281.281,0,0,1-.385-.261V10.11A2.246,2.246,0,0,1,10.86,7.864H26.58a2.246,2.246,0,0,1,2.246,2.246v4.522a.281.281,0,0,1-.385.261Z" transform="translate(0 0)" fill="#65d9d9" />
    </g>
  </svg>
)

export const IconBuilder = () => (
  <svg id="websitebuilder" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <path id="Path_45" data-name="Path 45" d="M32,8a8.119,8.119,0,0,1-2.422,5.625,7.6,7.6,0,0,1-7.453,2.125,2.971,2.971,0,0,0,1.047,1.609A2.836,2.836,0,0,0,25,18l3,3,2.563,2.656A4.709,4.709,0,0,1,32,27.109a4.658,4.658,0,0,1-1.437,3.438,4.923,4.923,0,0,1-6.906.016L21,28l-3-3a2.988,2.988,0,0,0-3-3l-.219-.219V21.75L7.063,31.719a6.5,6.5,0,0,1-2.7.281,6.226,6.226,0,0,1-2.672-.281L.281,30.344A6.5,6.5,0,0,1,0,27.641a6.5,6.5,0,0,1,.281-2.7L13.406,14.781,6.656,8H3L0,2,2,0,8,3V6.625l6.938,6.969L17,12a.028.028,0,0,1,.031-.031h.031A7.929,7.929,0,0,1,16,8a7.611,7.611,0,0,1,2.359-5.594A7.951,7.951,0,0,1,24,0V1.188L21.438,3.625A4.709,4.709,0,0,0,20,7.078a4.709,4.709,0,0,0,1.438,3.453,4.9,4.9,0,0,0,6.938,0L30.781,8ZM4,26a1.933,1.933,0,0,0-1.422.578A1.914,1.914,0,0,0,2,27.984a1.966,1.966,0,0,0,.578,1.422A1.907,1.907,0,0,0,4,30a1.907,1.907,0,0,0,1.422-.594A1.966,1.966,0,0,0,6,27.984a1.914,1.914,0,0,0-.578-1.406A1.933,1.933,0,0,0,4,26Z" fill="#65d9d9" />
  </svg>
)

export const IconCalendar = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28.8" height="32" viewBox="0 0 28.8 32">
    <g id="bxs-calendar" transform="translate(-6 -4)">
      <path id="Path_41" data-name="Path 41" d="M34.8,32.8V10.4a3.2,3.2,0,0,0-3.2-3.2H28.4V4H25.2V7.2H15.6V4H12.4V7.2H9.2A3.2,3.2,0,0,0,6,10.4V32.8A3.2,3.2,0,0,0,9.2,36H31.6A3.2,3.2,0,0,0,34.8,32.8ZM15.6,29.6H12.4V26.4h3.2Zm0-6.4H12.4V20h3.2ZM22,29.6H18.8V26.4H22Zm0-6.4H18.8V20H22Zm6.4,6.4H25.2V26.4h3.2Zm0-6.4H25.2V20h3.2Zm3.2-8H9.2V12H31.6Z" fill="#65d9d9" />
    </g>
  </svg>
)

export const IconDownload = () => (
  <svg id="downloadalt" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
    <path id="Path_49" data-name="Path 49" d="M12.25,14H1.75a1.686,1.686,0,0,1-1.237-.513A1.686,1.686,0,0,1,0,12.25V10.938a.852.852,0,0,1,.253-.615.826.826,0,0,1,.615-.26.849.849,0,0,1,.622.26.841.841,0,0,1,.26.615v.438a.864.864,0,0,0,.875.875h8.75A.852.852,0,0,0,11.99,12a.834.834,0,0,0,.26-.622v-.437a.852.852,0,0,1,.253-.615.826.826,0,0,1,.615-.26.887.887,0,0,1,.882.875V12.25A1.75,1.75,0,0,1,12.25,14Zm-4.8-3.691a.627.627,0,0,1-.9,0L1.887,5.592q-.164-.178-.116-.26t.253-.082H5.25V.875A.846.846,0,0,1,5.5.253.846.846,0,0,1,6.125,0h1.75A.846.846,0,0,1,8.5.253.846.846,0,0,1,8.75.875V5.25h3.213q.205,0,.26.082t-.109.26Z" transform="translate(0 0)" fill="#f5f5f5" />
  </svg>
)

export const IconEmailBig = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="19.872" viewBox="0 0 25 19.872">
    <g id="mail_solid" transform="translate(-0.6 -5.4)">
      <path id="Path_29" data-name="Path 29" d="M17.017,5.4H9.184a35.15,35.15,0,0,0-4.278.149,4.766,4.766,0,0,0-2.9,1.259,4.766,4.766,0,0,0-1.259,2.9A35.15,35.15,0,0,0,.6,13.983v2.7a35.151,35.151,0,0,0,.149,4.278,4.766,4.766,0,0,0,1.259,2.9,4.766,4.766,0,0,0,2.9,1.259,35.171,35.171,0,0,0,4.278.149h7.833a35.172,35.172,0,0,0,4.278-.149,4.766,4.766,0,0,0,2.9-1.259,4.766,4.766,0,0,0,1.259-2.9,35.172,35.172,0,0,0,.149-4.278v-2.7a35.172,35.172,0,0,0-.149-4.278,4.766,4.766,0,0,0-1.259-2.9,4.766,4.766,0,0,0-2.9-1.259A35.151,35.151,0,0,0,17.017,5.4ZM5.941,10.69a.962.962,0,0,0-1.067,1.6L8.344,14.6a29.684,29.684,0,0,0,2.518,1.575A4.749,4.749,0,0,0,13.1,16.8a4.749,4.749,0,0,0,2.238-.626c.709-.369,1.521-.91,2.518-1.575l3.47-2.313a.962.962,0,0,0-1.067-1.6l-3.425,2.284c-1.052.7-1.777,1.183-2.383,1.5a2.865,2.865,0,0,1-1.351.409,2.865,2.865,0,0,1-1.351-.409c-.606-.315-1.331-.8-2.383-1.5Z" transform="translate(0 0)" fill="#f5f5f5" fillRule="evenodd" />
    </g>
  </svg>
)

export const IconFlag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28.209" height="32" viewBox="0 0 28.209 32">
    <g id="flag" transform="translate(0 -2.625)">
      <path id="Path_43" data-name="Path 43" d="M17.719,6.416h10.49V25.192H15.074l-.793-3.7H3.791V34.625H0v-32H16.926Z" fill="#65d9d9" />
    </g>
  </svg>
)

export const IconLink = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
    <path id="Path_48" data-name="Path 48" d="M13.521,11.211l-2.311,2.311A1.53,1.53,0,0,1,10.069,14a1.574,1.574,0,0,1-1.155-.479L6.6,11.211a1.545,1.545,0,0,1-.465-1.06,1.594,1.594,0,0,1,.355-1.1L9.42,11.99a.911.911,0,0,0,.649.26.865.865,0,0,0,.636-.26l1.285-1.285a.865.865,0,0,0,.26-.636.911.911,0,0,0-.26-.649L9.051,6.494a1.594,1.594,0,0,1,1.1-.355,1.545,1.545,0,0,1,1.06.465l2.311,2.311A1.574,1.574,0,0,1,14,10.069,1.53,1.53,0,0,1,13.521,11.211ZM4.662,4.662a.892.892,0,0,1,.615-.273.773.773,0,0,1,.6.232L9.393,8.148a.741.741,0,0,1,.232.595.9.9,0,0,1-.273.622.866.866,0,0,1-.615.267.819.819,0,0,1-.6-.226L4.621,5.879a.773.773,0,0,1-.232-.6A.892.892,0,0,1,4.662,4.662ZM4.58,2.023a.843.843,0,0,0-.636-.273.887.887,0,0,0-.649.273L2.023,3.295a.887.887,0,0,0-.273.649.843.843,0,0,0,.273.636L4.949,7.506a1.594,1.594,0,0,1-1.1.355A1.545,1.545,0,0,1,2.789,7.4L.479,5.086A1.574,1.574,0,0,1,0,3.931,1.574,1.574,0,0,1,.479,2.775L2.789.479A1.53,1.53,0,0,1,3.931,0,1.574,1.574,0,0,1,5.086.479L7.4,2.775a1.6,1.6,0,0,1,.465,1.073,1.594,1.594,0,0,1-.355,1.1Z" fill="#f5f5f5" />
  </svg>
)

export const IconLocation = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="17.818" viewBox="0 0 14 17.818">
    <g id="location-filled" transform="translate(-7.5 -3)">
      <path id="Path_32" data-name="Path 32" d="M14.5,3a7.008,7.008,0,0,0-7,7,6.929,6.929,0,0,0,1.41,4.2s.191.251.222.288L14.5,20.818l5.37-6.334c.028-.034.22-.284.22-.284v0A6.927,6.927,0,0,0,21.5,10,7.008,7.008,0,0,0,14.5,3Zm0,9.545A2.545,2.545,0,1,1,17.045,10,2.545,2.545,0,0,1,14.5,12.545Z" transform="translate(0)" fill="#174fe9" />
      <circle id="IconifyId-17e9079768a-d1151c-107" cx="2.545" cy="2.545" r="2.545" transform="translate(11.955 7.455)" fill="none" />
    </g>
  </svg>
)

export const IconPageback = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14.006" height="14" viewBox="0 0 14.006 14">
    <g id="pageback" transform="translate(0 -0.022)">
      <path id="Path_31" data-name="Path 31" d="M13.131,14.022H11.38a.835.835,0,0,1-.622-.26.853.853,0,0,1-.253-.616V7.894a.835.835,0,0,0-.26-.622.853.853,0,0,0-.616-.253H7v3.132a.31.31,0,0,1-.185.26.8.8,0,0,1-.438.1.542.542,0,0,1-.4-.171L.246,5.856a.842.842,0,0,1,0-1.19L5.977.193a.542.542,0,0,1,.4-.171.8.8,0,0,1,.438.1A.318.318,0,0,1,7,.4V3.517h3.5a3.374,3.374,0,0,1,2.476,1.026,3.374,3.374,0,0,1,1.026,2.476v6.128a.888.888,0,0,1-.875.875Z" fill="#434343" />
    </g>
  </svg>
)

export const IconPrice = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13.986" viewBox="0 0 14 13.986">
    <g id="price" transform="translate(-0.043)">
      <path id="Path_33" data-name="Path 33" d="M13.8,9.3,9.353,13.74a.875.875,0,0,1-1.217,0L.289,5.893a1.617,1.617,0,0,1-.246-1.08V.875A.834.834,0,0,1,.3.253.852.852,0,0,1,.918,0H4.855A1.645,1.645,0,0,1,5.949.246L13.8,8.08a.864.864,0,0,1,.246.615A.818.818,0,0,1,13.8,9.3ZM3.543,1.736a1.686,1.686,0,0,0-1.237.513,1.686,1.686,0,0,0-.513,1.237,1.686,1.686,0,0,0,.513,1.237,1.686,1.686,0,0,0,1.237.513A1.686,1.686,0,0,0,4.78,4.724a1.686,1.686,0,0,0,.513-1.237A1.686,1.686,0,0,0,4.78,2.249,1.686,1.686,0,0,0,3.543,1.736Z" transform="translate(0)" fill="#174fe9" />
    </g>
  </svg>
)

export const IconRuler = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 14.545">
    <g id="bxs-ruler" transform="translate(-2 -14)">
      <path id="Path_40" data-name="Path 40" d="M30.909,14H5.091A3.007,3.007,0,0,0,2,16.909v8.727a3.007,3.007,0,0,0,3.091,2.909H30.909A3.007,3.007,0,0,0,34,25.636V16.909A3.007,3.007,0,0,0,30.909,14ZM10.727,21.273H7.818V16.909h2.909Zm5.818,1.455H13.636V16.909h2.909Zm5.818-1.455H19.455V16.909h2.909Zm5.818,1.455H25.273V16.909h2.909Z" fill="#65d9d9" />
    </g>
  </svg>
)

export const IconWhatsapp = () => (
  <svg id="whatsapp" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
    <path id="Path_50" data-name="Path 50" d="M13.281,23.437a11.615,11.615,0,0,1-5.688-1.465L0,25l3.027-7.593a11.615,11.615,0,0,1-1.465-5.688A11.42,11.42,0,0,1,2.49,7.166,11.594,11.594,0,0,1,8.728.928a11.637,11.637,0,0,1,9.106,0,11.594,11.594,0,0,1,6.238,6.238,11.637,11.637,0,0,1,0,9.106,11.594,11.594,0,0,1-6.238,6.238A11.42,11.42,0,0,1,13.281,23.437Zm3.906-9.375H15.625l-.879.781a6.832,6.832,0,0,1-2.7-1.892,6.831,6.831,0,0,1-1.892-2.7l.781-.879V7.813a1.433,1.433,0,0,0-.293-.83,1.852,1.852,0,0,0-.647-.6q-.354-.183-.5-.037L8.35,7.5q-.952.952-.281,2.966a10.136,10.136,0,0,0,2.563,3.906,10.136,10.136,0,0,0,3.906,2.563q2.014.671,2.966-.281L18.652,15.5q.146-.146-.037-.5a1.852,1.852,0,0,0-.6-.647A1.433,1.433,0,0,0,17.188,14.062Z" fill="#f5f5f5" />
  </svg>
)

