export const Logo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="193.226" height="15.933" viewBox="0 0 193.226 15.933">
    <g id="Group_12" transform="translate(1014.849 -1462.016)">
      <path id="Path_2" d="M-1009.546,1466.419a2.294,2.294,0,0,1,1.838-.832c.15,0,.64-.02.64-.269a.177.177,0,0,0-.051-.138.75.75,0,0,0-.51-.1h-6.687a.682.682,0,0,0-.483.1.177.177,0,0,0-.051.138c0,.266.634.269.64.269a2.3,2.3,0,0,1,1.836.83,2.732,2.732,0,0,1,.2,1.21v9.606a3.772,3.772,0,0,1-.2,1.472,2.293,2.293,0,0,1-1.838.833c-.006,0-.64,0-.64.269a.178.178,0,0,0,.051.138.68.68,0,0,0,.483.1h6.687a.749.749,0,0,0,.51-.1.178.178,0,0,0,.051-.138c0-.249-.488-.269-.636-.269h0a2.3,2.3,0,0,1-1.841-.832,3.248,3.248,0,0,1-.2-1.473v-9.606A2.737,2.737,0,0,1-1009.546,1466.419Z" transform="translate(0 -2.102)" fill="#020202" />
      <path id="Path_3" d="M-955.993,1465.076h-4.543c-.327,0-.876.03-.876.234,0,.121.158.188,1.267.42.181.038.368.077.406.093.712.221.919.45.919,1.541v9.985l-10.7-9.609c-.22-.2-.436-.406-.664-.627-1.022-.989-2.079-2.012-3.6-2.012h-2.978c-.431,0-.876,0-.876.234,0,.123.157.155.578.239.1.021.214.044.332.07,1.686.378,1.837.983,1.837,2.617v8.677c0,1.444-.206,1.716-.916,1.9l-.76.2c-.263,0-.917.09-.917.286,0,.24.452.26.876.26h4.544c.431,0,.876,0,.876-.234,0-.205-.582-.309-.917-.311l-.76-.2c-.78-.212-.916-.531-.916-2.13v-9.728l14.033,12.685a2.355,2.355,0,0,0,1.315.383c.479,0,.722-.182.722-.543v-11.551c0-1.921.14-2.006,1.657-2.334.1-.023.22-.041.333-.058.363-.056.6-.092.6-.252C-955.117,1465.093-955.787,1465.076-955.993,1465.076Z" transform="translate(-25.563 -2.102)" fill="#020202" />
      <path id="Path_4" d="M-869.6,1465.076h-6.762l-.3.108.013.068c.052.272.456.388.968.535.676.193,1.442.413,1.442,1.022a2.522,2.522,0,0,1-.459,1.214l-5.985,9.509-5.619-8.933a4.894,4.894,0,0,1-.983-2.053c0-.459.415-.747,1.432-.995l.026-.012a.281.281,0,0,0,.141-.252v-.066l-.065-.014a6.852,6.852,0,0,0-1.406-.133h-5.032c-.193,0-1.156.01-1.156.213,0,.166.2.233.573.355a5.861,5.861,0,0,1,3.45,2.65l6.578,10.431c.56.9.9,1.323,1.3,1.323.331,0,.7-.37,1.328-1.322l6.5-10.274c.907-1.433,2.087-2.9,3.981-2.9a.73.73,0,0,0,.411-.154l.024-.024v-.034C-869.2,1465.146-869.386,1465.1-869.6,1465.076Z" transform="translate(-83.466 -2.102)" fill="#020202" />
      <path id="Path_5" d="M-787.174,1476.215c-.222,0-.249.218-.265.348a.583.583,0,0,1-.024.129,4.118,4.118,0,0,1-.646,1.294c-.789.947-3.188.947-4.476.947h-1.082c-1.09,0-2.163-.026-4.138-.158-.058,0-.121-.006-.188-.007-.432-.011-.738-.045-.738-.385v-5.8h6.847a1.85,1.85,0,0,1,1.435,1.2l.008.026a.825.825,0,0,1,.07.386c0,.15,0,.32.214.32.267,0,.267-.366.267-.689v-3.642c0-.311,0-.663-.241-.663-.194,0-.228.21-.268.454a1.523,1.523,0,0,1-1.474,1.473h-6.859v-4.589a.548.548,0,0,1,.261-.422,32.876,32.876,0,0,1,3.932-.182h1.821c1.723,0,3.385.067,3.874.556a1.582,1.582,0,0,1,.353.584,3,3,0,0,1,.257,1.057c.017.135.061.495.293.495.41,0,.558-3.113.558-3.144v-.291c0-.309-.023-.425-.267-.425-.041,0-.081,0-.14.008-.168.014-.518.044-1.628.044H-803.09c-.287,0-.769.024-.769.188s.293.242,1.068.407a4.442,4.442,0,0,1,.672.171c.794.333.979.582.979,2.273v8.9c0,1.932-.188,1.983-1.65,2.378l-.085.023a1.726,1.726,0,0,1-.23.03c-.312.03-.7.066-.7.313,0,.22.414.241,1.508.241h13.514c1.687,0,1.773-1.94,1.83-3.223.007-.148.013-.289.021-.418v-.016l-.005-.015A.208.208,0,0,0-787.174,1476.215Z" transform="translate(-144.938 -2.102)" fill="#020202" />
      <path id="Path_6" d="M-715.792,1465.076h-4.544c-.327,0-.876.03-.876.234,0,.121.158.188,1.267.42.181.038.368.077.407.093.712.221.919.45.919,1.541v9.985l-10.7-9.609c-.22-.2-.436-.406-.664-.627-1.022-.989-2.079-2.012-3.6-2.012h-2.978c-.43,0-.876,0-.876.234,0,.123.157.155.578.239.1.021.215.044.332.07,1.687.378,1.837.983,1.837,2.617v8.677c0,1.444-.206,1.716-.916,1.9l-.76.2c-.263,0-.917.09-.917.286,0,.24.452.26.876.26h4.544c.43,0,.876,0,.876-.234,0-.205-.582-.309-.917-.311l-.76-.2c-.779-.212-.917-.531-.917-2.13v-9.728l14.033,12.685a2.355,2.355,0,0,0,1.315.383c.479,0,.722-.182.722-.543v-11.551c0-1.921.14-2.006,1.656-2.334.1-.023.22-.041.334-.058.363-.056.6-.092.6-.252C-714.916,1465.093-715.586,1465.076-715.792,1465.076Z" transform="translate(-190.568 -2.102)" fill="#020202" />
      <path id="Path_7" d="M-635.372,1465.076c-.281,0-.782.035-1.362.076-.712.05-1.519.107-2.071.107h-10.326c-.552,0-1.359-.057-2.071-.107-.58-.041-1.081-.076-1.362-.076-.339,0-.58,2.586-.58,3.41v.577l0,.025a.244.244,0,0,0,.235.161h.026l.022-.016a3.275,3.275,0,0,0,.6-1.079,7.048,7.048,0,0,1,.361-.752c.474-.774.9-.9,1.948-.9h4.767v10.612a3.877,3.877,0,0,1-.2,1.611,2.265,2.265,0,0,1-1.816.823c-.007,0-.632,0-.632.266a.175.175,0,0,0,.05.136.671.671,0,0,0,.477.1h6.6a.738.738,0,0,0,.5-.1.175.175,0,0,0,.05-.136c0-.246-.482-.266-.628-.266h0a2.271,2.271,0,0,1-1.817-.819,2.708,2.708,0,0,1-.2-1.2V1466.5h4.794c1.046,0,1.474.131,1.948.9a7.126,7.126,0,0,1,.361.752,3.288,3.288,0,0,0,.6,1.079l.022.016h.027a.244.244,0,0,0,.235-.161l0-.6C-634.792,1467.662-635.033,1465.076-635.372,1465.076Z" transform="translate(-248.47 -2.102)" fill="#020202" />
      <path id="Path_8" d="M-565.4,1466.692a12.065,12.065,0,0,0-6.239-1.616,11.955,11.955,0,0,0-8.775,3.483,6.493,6.493,0,0,0-1.749,4.3c0,4.9,5.558,7.19,9.314,7.19a13.011,13.011,0,0,0,8.247-2.733,6.484,6.484,0,0,0,2.6-5.025A6.788,6.788,0,0,0-565.4,1466.692Zm-6.14,12.164c-3.91,0-8.1-2.648-8.1-6.591a5.89,5.89,0,0,1,2.074-4.314,7.177,7.177,0,0,1,4.863-1.734,9.562,9.562,0,0,1,3.87.832c2.068.959,4.277,2.995,4.277,5.833C-564.555,1476.573-568.181,1478.857-571.54,1478.857Z" transform="translate(-297.231 -2.102)" fill="#020202" />
      <g id="Group_11" transform="translate(-835.528 1462.016)">
        <path id="Path_9" d="M-438.846,1465.876a1.079,1.079,0,0,0-1.152-.826h-1.784c-.394,0-.3.39.03.39.7,0,.973.154,1.362,1.052l3.339,7.352V1469.8Z" transform="translate(442.041 -1464.1)" fill="#020202" />
        <path id="Path_10" d="M-411.771,1465.876a1.079,1.079,0,0,1,1.152-.826h1.783c.394,0,.3.39-.03.39-.7,0-.973.154-1.362,1.052l-3.339,7.352V1469.8Z" transform="translate(422.481 -1464.1)" fill="#020202" />
        <path id="Path_11" d="M-426.271,1463.456a1.5,1.5,0,0,0-.121-.7,1.434,1.434,0,0,0-1.107-.5c-.182,0-.334-.03-.334-.121,0-.076.121-.121.3-.121.621,0,1.274.03,2.2.03.94,0,1.592-.03,2.228-.03.182,0,.3.045.3.121,0,.091-.152.121-.334.121a1.465,1.465,0,0,0-1.122.5,1.5,1.5,0,0,0-.121.7V1476.4a1.656,1.656,0,0,0,.121.8,1.464,1.464,0,0,0,1.122.5c.182,0,.334.03.334.121,0,.076-.121.121-.3.121-.621,0-1.289-.03-2.213-.03-.94,0-1.592.03-2.213.03-.182,0-.3-.045-.3-.121,0-.091.152-.121.334-.121a1.433,1.433,0,0,0,1.107-.5,1.657,1.657,0,0,0,.121-.8Z" transform="translate(432.28 -1462.016)" fill="#174fe9" />
      </g>
      <path id="Path_12" d="M-488.793,1479.625c-.046-.007-.1-.022-.161-.037a5.4,5.4,0,0,1-2.261-1.677,34.589,34.589,0,0,1-2.838-5.615l-.245-.565c.19-.017.348-.034.462-.048a3.381,3.381,0,0,0,.5-.1c1.207-.336,3.274-1.189,3.274-3.09,0-3.419-5.359-3.419-7.651-3.419-.855,0-1.725.019-2.567.034s-1.691.034-2.53.034h-1.932c-.279,0-.5-.006-.6.094a.149.149,0,0,0-.047.122c0,.034.025.075.081.128a1.322,1.322,0,0,0,.485.106c.016,0,.088.016.138.022.085.016.138.025.147.025l.138.031a4.657,4.657,0,0,1,.532.15c.57.229.773.379.817,1.174v10.428a3.427,3.427,0,0,1-.178,1.437,2.015,2.015,0,0,1-1.618.736c-.006,0-.563,0-.563.235a.155.155,0,0,0,.044.122.586.586,0,0,0,.426.094h5.895a.644.644,0,0,0,.448-.094.155.155,0,0,0,.044-.122c0-.219-.429-.235-.56-.235a2.873,2.873,0,0,1-.939-.2,1.315,1.315,0,0,1-.682-.532,3.455,3.455,0,0,1-.178-1.44v-5.61h4.5c.007.014.013.022.02.038,1.435,3.517,3.926,8.194,6.266,8.194h.932c.507,0,.7.035.7-.135C-488.507,1479.913-488.449,1479.677-488.793,1479.625Zm-12.124-13a.457.457,0,0,1,.488-.441c.313,0,.711-.022,1.174-.047.62-.031,1.321-.069,2.054-.069,3.156,0,4.627.755,4.627,2.382,0,1.747-2.025,2.382-3.387,2.382l-4.956-.047Z" transform="translate(-349.954 -2.101)" fill="#020202" />
    </g>
  </svg>
)
