import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BoatCard } from "../../../components/boatCard";
import { Dropdown } from "../../../components/form/dropdown";
import { Filter } from '../../../components/filter';
import styled from "styled-components";

import { GridButtonIcon } from "../../../components/icon/gridButtonIcon";
import { ListButtonIcon } from "../../../components/icon/listButtonIcon";
import BrokerageApi from "../../../services/api.service";
import { Helmet } from "react-helmet";
import { queryStringToJson } from "../../../services/store.service";
import { Loader } from "../../../components/loader";
import { useParams } from "react-router-dom";
import { getPossibleImage } from "../../../services/image.service";

const ListWrapper = styled.div`
  background: #E8E8E8;
  min-height: calc(100vh - 275px);
  padding-bottom: 48px;
`;

const ListHeader = styled.div`
  padding-top: 48px;
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: 600;
  text-align: center;
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
  text-align: center;
  line-height: 1.3;
  color: #8B8B8B;
`

const Filters = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
`;

const ListContent = styled.div`
`;

const ViewSwitch = styled.div`
  display: flex;
  justify-content: flex-start;

  @media (max-width: 992px) {
    padding-top: 15px;
  }
`;

const DropdownSwitch = styled.div`
  display: flex;
  justify-content: flex-end;
`

const GridButton = styled.div`
  margin-right: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ListButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SwitchWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
`

const Switch = styled.div`
  display: flex;
  border-radius: 16px; 
  overflow: hidden;
  border: 1px solid #02020217;
`

const SwitchItem = styled.a`
  padding: 5px 20px;
  border: 1p solid #000;
  text-decoration: none;
  color: #000;
  display: block;
  background: #02020217;
  transition-duration: 0.3s;

  :hover {
    background: #E8E8E8;
  }

  &.active {
    background: #f5f5f5;
    color: #174FE9;
  }
  
`


const EmptyMessage = styled.div`
  text-align: center;
  margin: 32px;
  font-size: 56px;
  font-weight: bold;
  color: #8b8b8b;
`;

export const BoatListPage = () => {
  const { status } = useParams();

  const [viewMode, setViewMode] = React.useState(0);
  const [data, setData] = React.useState(0);
  const [loading, setLoading] = React.useState(true);

  const init = async (sorting = "") => {
    const query = queryStringToJson(window.location.search);

    if (status == "new" || status == "preowned" || status == "special") {
      query.status = status;
    }

    const result = await BrokerageApi.getBoats(query, sorting);

    if (result.success) {
      setData(result.data);
      setLoading(false);
    } else {
      // an error occured
    }
  };

  React.useEffect(() => {
    console.log("init");
    init();
  }, [window.location, window.location.search]);

  const onViewSwitch = (i) => {
    setViewMode(i);
  };

  const onSort = (v) => {
    init(v);
  };

  const getTitle = () => {
    if (status == "new") return "New Inventory";
    if (status == "preowned") return "Pre-Owned Inventory";
    if (status == "special") return "Special Inventory";

    return "Inventory";
  }

  if (loading) return <Loader />;


  return (
    <React.Fragment>
      <Helmet>
        <title>Iconic Yacht Inventory</title>
        <meta name="description" content="Welcome to Iconic Yacht Group's Inventory website, where luxury meets the sea. Explore our curated selection of premium yachts, featuring top-notch brands such as Invictus, Fountain, Donzi, Baja, YOT, Steeler, Capoforte, and Nauticstar. Immerse yourself in the world of maritime elegance as you browse through our inventory of exquisite vessels, each embodying the epitome of craftsmanship and design. Whether you seek the thrill of high-speed adventures or the tranquility of leisurely cruises, our collection is tailored to satisfy the discerning tastes of yacht enthusiasts. Discover the art of nautical excellence with Iconic Yacht Group's exclusive Inventory – your gateway to a world where luxury and performance converge on the open waters." />
      </Helmet>
      <Filter />
      <ListWrapper>
        <ListHeader>
          <Container>
            <Row>
              <Col>

                <SwitchWrapper>
                  <Switch>
                    <SwitchItem href="/" className={`${status == undefined ? 'active' : ''}`}>All</SwitchItem>
                    <SwitchItem href="/new" className={`${status == "new" ? 'active' : ''}`}>New</SwitchItem>
                    <SwitchItem href="/preowned" className={`${status == "preowned" ? 'active' : ''}`}>Pre-Owned</SwitchItem>
                    <SwitchItem href="/special" className={`${status == "special" ? 'active' : ''}`}>Special</SwitchItem>
                  </Switch>
                </SwitchWrapper>

              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Title>{getTitle()}</Title>
              </Col>

              <Col md={2}></Col>
              <Col md={8}>
                <Description>
                  Welcome to Iconic Yacht Group's Inventory website, where luxury meets the sea. Explore our curated selection of premium yachts, featuring top-notch brands such as <strong>Fountain</strong>, <strong>Donzi</strong>, <strong>Baja</strong>, <strong>YOT</strong>, <strong>Dutch American</strong>, <strong>Invictus</strong>, and <strong>Opus</strong>. Immerse yourself in the world of maritime elegance as you browse through our inventory of exquisite vessels, each embodying the epitome of craftsmanship and design. Whether you seek the thrill of high-speed adventures or the tranquility of leisurely cruises, our collection is tailored to satisfy the discerning tastes of yacht enthusiasts. Discover the art of nautical excellence with Iconic Yacht Group's exclusive Inventory – your gateway to a world where luxury and performance converge on the open waters.
                </Description>
              </Col>
              <Col md={2}></Col>
            </Row>
            <Filters>
              <Row>
                <Col md={1} className="desktop">
                  <ViewSwitch>
                    <GridButton
                      selected={viewMode === 0}
                      onClick={() => onViewSwitch(0)}
                    >
                      <GridButtonIcon selected={viewMode === 0} />
                    </GridButton>
                    <ListButton
                      selected={viewMode === 1}
                      onClick={() => onViewSwitch(1)}
                    >
                      <ListButtonIcon selected={viewMode === 1} />
                    </ListButton>
                  </ViewSwitch>
                </Col>

                <Col md={7} className="desktop"></Col>
                <Col md={4} >
                  <DropdownSwitch>
                    <Dropdown
                      placeholder="Sort by"
                      fullWidth
                      dark
                      horizontal
                      options={[
                        {
                          value: "created_at:desc",
                          label: "Sort by: Recommended",
                        },
                        {
                          value: "lengthM:desc",
                          label: "Sort by: Length - Long to Short",
                        },
                        {
                          value: "lengthM:asc",
                          label: "Sort by: Length - Short to long",
                        },
                        {
                          value: "price:desc",
                          label: "Sort by: Price - High to Low",
                        },
                        { value: "price:asc", label: "Sort by: Price - Low to High" },
                      ]}
                      onChange={onSort}
                    />

                  </DropdownSwitch>
                </Col>
              </Row>
            </Filters>

          </Container>
        </ListHeader>
        <ListContent>

          <Container>
            <Row>
              {data.length == 0 ? (
                <Col>
                  <EmptyMessage>No result found.</EmptyMessage>
                </Col>
              ) : (
                data.map((boat, i) => (
                  <Col xl={viewMode == 0 ? 6 : 12} lg={viewMode == 0 ? 6 : 12} key={i}>
                    <BoatCard
                      id={boat.id}
                      list={viewMode == 1}
                      handle={boat.handle}
                      title={`${boat.builder ? boat.builder.name : ""} ${boat.model}`}
                      year={boat.built}
                      lengthM={boat.loa}
                      cabins={boat.cabins}
                      location={boat.location}
                      price={boat.price}
                      currency={boat.currency || "usd"}
                      stockNumber={boat.stockNumber}
                      enginePower={boat.enginePower}
                      listImage={getPossibleImage(boat.listImage, "large")}
                    />
                  </Col>
                ))
              )}
            </Row>
          </Container>
        </ListContent>
      </ListWrapper>
    </React.Fragment>
  );
};
