import { config } from "./config.service";

const sizes = ["large", "medium", "small", "thumbnail"];

export const getPossibleImage = (file, size) => {
  if (!file) return "";
  let url = `${config.apiURL}${file.url}`;

  if (!size) return url;

  if (size != "thumbnail" && size != "small" && size != "medium" && size != "large") {
    throw "Size must be thumbnail | small | medium | large";
  }

  if (!file.formats) return url;

  const index = sizes.indexOf(size);

  if (index < 0) return url;

  for (let i = index; i < sizes.length; i++) {
    if (file.formats[sizes[i]]) {
      url = `${config.apiURL}/uploads/${file.formats[sizes[i]].hash}${file.formats[sizes[i]].ext}`
      break;
    }
  }

  return url;
}

