export const formatCurrency = (amount, symbol) => {
  // Create our number formatter.
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: symbol,
    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  return formatter.format(amount);
};

export const jsonToQueryString = (json) => {
  return (
    "?" +
    Object.keys(json)
      .filter((key) => json[key])
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(json[key])
      )
      .join("&")
  );
};

export const queryStringToJson = (query) => {
  var obj = {};

  if (query.indexOf("?") == -1) return obj;
  var parts = query.split("?");
  var pairs = parts[1].split("&");

  if (pairs.length > 0) {
    for (var i = 0; i < pairs.length; i++) {
      var pp = pairs[i].split("=");
      if (pp.length == 2) {
        obj[pp[0]] = pp[1].replaceAll("%20", " ");
      }
    }
  }

  return obj;
};
