export const GridButtonIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="grid" transform="translate(-0.281 -0.281)">
      <g id="Group_13" data-name="Group 13" transform="translate(0.281 0.281)">
        <path id="Path_16" data-name="Path 16" d="M9.5,11.424H2.21A1.929,1.929,0,0,1,.281,9.5V2.21A1.929,1.929,0,0,1,2.21.281H9.5A1.929,1.929,0,0,1,11.424,2.21V9.5A1.929,1.929,0,0,1,9.5,11.424Z" transform="translate(-0.281 -0.281)"
          fill={props.selected ? "#174fe9" : "#8b8b8b"}
        />
        <path id="Path_17" data-name="Path 17" d="M11.6,11.424H4.319A1.929,1.929,0,0,1,2.391,9.5V2.21A1.929,1.929,0,0,1,4.319.281H11.6A1.929,1.929,0,0,1,13.533,2.21V9.5A1.929,1.929,0,0,1,11.6,11.424Z" transform="translate(10.467 -0.281)"
          fill={props.selected ? "#174fe9" : "#8b8b8b"}
        />
        <path id="Path_18" data-name="Path 18" d="M9.5,13.533H2.21A1.929,1.929,0,0,1,.281,11.6V4.319A1.929,1.929,0,0,1,2.21,2.391H9.5a1.929,1.929,0,0,1,1.929,1.929V11.6A1.929,1.929,0,0,1,9.5,13.533Z" transform="translate(-0.281 10.467)"
          fill={props.selected ? "#174fe9" : "#8b8b8b"}
        />
        <path id="Path_19" data-name="Path 19" d="M11.6,13.533H4.319A1.929,1.929,0,0,1,2.391,11.6V4.319A1.929,1.929,0,0,1,4.319,2.391H11.6a1.929,1.929,0,0,1,1.929,1.929V11.6A1.929,1.929,0,0,1,11.6,13.533Z" transform="translate(10.467 10.467)"
          fill={props.selected ? "#174fe9" : "#8b8b8b"}
        />
      </g>
    </g>
  </svg>
);
