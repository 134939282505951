export const config = {
  dev: process.env.REACT_APP_ENVIRONMENT == "dev",
  publicURL:
    process.env.REACT_APP_ENVIRONMENT == "dev"
      ? "http://localhost:3000"
      : "https://iconicyachtinventory.com",
  apiURL:
    process.env.REACT_APP_ENVIRONMENT == "dev"
      ? "http://localhost:8000"
      : "https://api.iconicyachtinventory.com",
};
