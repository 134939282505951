import React from "react";
import styled from "styled-components";
import Select from 'react-select'

const DropdownWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: ${(props) => (props.horizontal ? "center" : "")};
  flex-direction: ${(props) => (props.horizontal ? "row" : "column")};

  .select {
    flex: 1;
    width: 100%;
  }

  .label {
    margin-right: 16px;
    font-size: 14px;
    color: #8b8b8b;
  }
`

export const Dropdown = (props) => {
  const onChange = (e) => {
    if (props.onChange) props.onChange(e ? e.value : undefined);
  };

  return (
    <DropdownWrapper horizontal={props.horizontal}>
      <span className="label">{props.label}</span>
      <Select
        className="select"
        options={props.options}
        defaultValue={props.value ? ({ label: props.value, value: props.value }) : undefined}
        placeholder={props.placeholder || "Select..."}
        onChange={onChange}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: '#e1e7f6',
          },
        })}

        styles={{
          container: (baseStyles) => ({
            ...baseStyles,
            outline: 0
          }),

          indicatorSeparator: (b) => ({ ...b, display: "none" }),
          menuList: (b) => ({
            ...b,
            background: '#f5f5f5',
            fontSize: 14,
            color: '#8b8b8b'
          }),

          control: (baseStyles, state) => ({
            ...baseStyles,
            background: props.dark ? '#e8e8e8' : '#f1f1f1',
            border: 'none',
            borderBottom: '1px solid #c1c1c1',
            borderColor: state.isFocused ? '#174fe9' : '#c1c1c1',
            borderRadius: 0,
          })

        }}

      />

      {/*
      <SelectInput onChange={onChange} fullWidth={props.fullWidth} value={props.value}>
        {props.default && <option value="">{props.default}</option>}

        {props.options.map((option, i) => (
          <option key={i} value={option.key || option}>
            {option.value || option}
          </option>
        ))}
      </SelectInput>
      */}
    </DropdownWrapper>
  );
};
