import styled from "styled-components";
import { config } from "../../services/config.service";
import { IconEmailBig, IconWhatsapp } from "../Icons";

const Wrapper = styled.div`
  background: #ffffff;
  padding: 20px;
  border-radius: 26px;
  display: flex;
  justify-content: space-between;

  @media(max-width: 991px) {
    margin-top: 24px;
  }
`

const Picture = styled.div`
  img {
    border-radius: 13px;
  }
`

const Information = styled.div`
  margin-top: 32px;

  .title {
    color: #8B8B8B;
    font-size: 12px;
    font-weight: 500;
  }

  .name {
    color: #174FE9;
    font-size: 22px;
    font-weight: 600;
  }
`

const Contact = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

`

const ContactIcon = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 57px;
  height: 57px;
  background: #174FE9;
  margin: 0 6px;
  border-radius: 13px;
`

const Vertical = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .phone {
    text-decoration: none;
    color: #020202;
    font-weight: 500;
  }
`

export const BrokerCard = (props) => {
  const message = `I would like to know more information about ${props.model} @ ${props.link}`;

  return (
    <Wrapper>
      <div>

        <Picture>
          <img src={`${config.apiURL}${props.image.url}`} width="160" alt="" />
        </Picture>

        <Information>
          <div className="title">{props.title}</div>
          <div className="name">{props.fullname}</div>
        </Information>
      </div>
      <Vertical>
        <Contact>
          <ContactIcon>
            <a href={`https://wa.me/${props.whatsapp}?text=${encodeURI(message)}`} target="_blank">
              <IconWhatsapp />
            </a>
          </ContactIcon>
          <ContactIcon>
            <a href={`mailto:${props.email}`}>
              <IconEmailBig />
            </a>
          </ContactIcon>
        </Contact>

        <a className="phone" href={`tel:${props.phone}`} target="_blank">
          {props.phone}
        </a>
      </Vertical>

    </Wrapper >
  );
};
