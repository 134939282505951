import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import BrokerageApi from "../../../services/api.service";
import { BrokerCard } from "../../../components/brokerCard";
import { TextBox } from "../../../components/form/textbox";
import { Button } from "../../../components/form/button";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { config } from "../../../services/config.service";
import { formatCurrency } from "../../../services/store.service";
import { Helmet } from "react-helmet";
import { Loader } from "../../../components/loader";
import { Center, Right } from '../../../components/Styled'
import { IconArrorRight, IconBoat, IconBuilder, IconCalendar, IconDownload, IconLocation, IconPageback, IconPrice, IconRuler } from "../../../components/Icons";
import { getPossibleImage } from "../../../services/image.service";

const PageWrapper = styled.div`
  padding-bottom: 64px;
`;

const Breadcrumb = styled.div`
  .active {
    font-weight: 500;
    color: #174FE9;
  }
`;

const BreadcrumbDivider = styled.span`
  font-size: 12px;
  margin: 0 10px;
`;

const BreadcrumbItem = styled.span`
  font-size: 12px;

  & a {
    color: #8B8B8B !important;
    text-decoration: none !important;
  }
`;

const ContactCard = styled.div`
  background: #E8E8E8;
  border-radius: 26px;
  padding: 32px;

  .title {
    font-size: 18px;
    font-weight: 600;
    color: #174FE9;
  }

  .name {
    color: #020202;
    font-size: 28px;
    font-weight: 600;
  }
`

const InformationCard = styled.div`
  padding: 32px;
  background: #ffffff;
  border-radius: 26px;
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 13px 16px #00000008;

  .boat-name {
    border-right: 1px solid rgb(112, 112, 112); 
    padding-right: 32px;
  }

  .builder {
    color: #8B8B8B;
    font-weight: 500;
  }

  .model {
    color: #020202;
    font-size: 36px;
    font-weight: 600;
  }

  .subtitle {
    color: #174FE9;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .info {
    display: flex;
    flex: 1;
    align-items: center;

    .item {
      margin-left: 48px;
    }
  }

  .value {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 500;

    .icon {
      margin-right: 8px;
    }
  }

  @media(max-width: 991px) {
    flex-direction: column;

    .boat-name {
      border: none;
    }

    .info {
      justify-content: space-around;
      width: 100%;
      margin-top: 24px;

      .item {
        margin: 0;
      }

    }
  }

  @media(max-width: 1199px) {
    .button {
      display: none;
    }
  }
`

const ModelHeader = styled.div`
  margin-top: 140px;
`;

const Title = styled.div`
  font-weight: 600;
  letter-spacing: 1.6;
  font-size: 22px;
  margin-bottom: 35px;
`;

const Specifications = styled.div`
  margin-top: 100px;
`;

const ModelContent = styled.div`
  padding-top: 48px;
  text-align: center;

  div {
    font-size: 16px !important;
    color: #434343 !important;
    font-weight: 500 !important;
    line-height: 24px !important;
  }
`;

const GalleryWrapper = styled.div`
  margin-top: 32px;

  .image-gallery-slide .image-gallery-image {
    object-fit: cover;
  }

  @media (max-width: 992px) {
    padding-top: 45px;
  }
`;

const VideoWrapper = styled.div`
  padding-top: 100px;
`;

const BrokerWrapper = styled.div`
  padding-top: 100px;
`;

const Top = styled.div`
  padding-top: 40px;
`

const Back = styled.div`
  a {
    text-decoration: none;
    color: #434343;
    display: flex;
    align-items: center;
    font-size: 14px;  

    .icon {
      margin-right: 8px;
    }
  }
`

const SpecCardIcon = styled.div`
  background: ${(props) => (props.inverse ? "#174FE9" : "#FFFFFF")};
  border-radius: 26px;
  padding: 20px;
  box-shadow: 0px 7px 16px #0000001A;
  font-weight: 500;
  margin-bottom: 24px;

  a {
    color: #fff;
    text-decoration: none;
    font-size: 12px;
    font-weight: 500;
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .bottom {
    margin-top: 64px;

    .title {
      color: ${props => props.inverse ? '#c1c1c1' : '#8B8B8B'};
    }

    .text {
      font-size: 22px;
      font-weight: 500;
      color: ${props => props.inverse ? '#f1f1f1' : '#174FE9'};
    }
  }

  .extra-mobile {
    display: none;
  }
  
  @media(max-width: 991px) {
    margin-bottom: 24px;
    display: flex;
    justify-content: ${p => p.invers ? 'space-between' : 'flex-start'}
    width: 100%;

    .extra {
      display: none;
    }

    .extra-mobile {
      display: block;
    }

    .bottom {
      margin-top: 0;
      margin-left: 32px;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    
  }
`

const SpecCard = styled.div`
  background: ${(props) => (props.inverse ? "#174FE9" : "#FFFFFF")};
  border-radius: 26px;
  padding: 20px;
  box-shadow: 0px 7px 16px #0000001A;
  font-weight: 500;
  margin-bottom: 24px;

  .title {
    color: ${props => props.inverse ? '#c1c1c1' : '#8B8B8B'};
  }

  .text {
    font-size: 22px;
    font-weight: 500;
    color: ${props => props.inverse ? '#f1f1f1' : '#174FE9'};
  }

  @media(max-width: 991px) {
    margin-bottom: 24px;
  }

`

export const BoatDetailPage = () => {
  const [loading, setLoading] = React.useState(true);
  const [disableButton, setDisableButton] = React.useState(false);
  const [data, setData] = React.useState({});
  const [gallery, setGallery] = React.useState([]);
  const [contactForm, setContactForm] = React.useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    message: "",
  });
  const [message, setMessage] = React.useState("");

  let params = useParams();

  const init = async () => {
    const result = await BrokerageApi.getBoat(params.id);

    if (result.success) {
      setData(result.data);

      if (result.data.photoGallery && result.data.photoGallery.length > 0) {
        const photoGallery = result.data.photoGallery.filter(x => x.image).map((x) => ({
          loading: 'lazy',
          original: getPossibleImage(x.image, "large"),
          thumbnail: getPossibleImage(x.image, "thumbnail")
        }));

        setGallery(photoGallery);
      }

      setLoading(false);
    } else {
      // an error occured
    }
  };

  const setContactFormField = (key, value) => {
    contactForm[key] = value;
    setContactForm(contactForm);
  };

  const onContactSend = async (e) => {
    e.preventDefault();

    setDisableButton(true);

    if (e.target.checkValidity()) {
      await BrokerageApi.postContact(data.handle, {
        ...contactForm,
        fullname: contactForm.firstName + " " + contactForm.lastName,
      });
      setMessage("Your message has been sent!");
      setMessage("Thank you for your interest in our yachts! Our team will get in touch with you shortly to discuss your preferences and provide you with further information. We look forward to the opportunity to assist you in finding the perfect vessel that matches your expectations. If you have any immediate questions or specific requests. Once again, thank you for considering Iconic Yacht Group, and we'll be in touch soon!")
      setDisableButton(false);

      setContactForm({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        message: "",
      });
    }
  };

  React.useEffect(() => {
    init();
  }, [params]);

  if (loading) return <Loader />;

  return (
    <PageWrapper>
      <Helmet>
        <title>
          {`${data.builder.name} ${data.model} ${data.built} `} | Iconic Yacht
          Inventory
        </title>

        <meta name="description" content={`${data.builder.name} ${data.model} ${data.built} is now available for sale! Location is Florida, priced at $1,000,000. Explore the perfect blend of elegance and performance. ${data.builder.name} ${data.model} for sale`} />
        <meta property="og:description" content={`${data.builder.name} ${data.model} ${data.built} is now available for sale! Location is Florida, priced at $1,000,000. Explore the perfect blend of elegance and performance. ${data.builder.name} ${data.model} for sale`} />

        {data.listImage && (
          <meta property="og:image" content={`${config.apiURL}${data.listImage.url}`} />
        )}
      </Helmet>

      <Top>
        <Container>
          <Row>

            <Col md={6}>
              <Breadcrumb>
                <Container>
                  <Row>
                    <Col>
                      <BreadcrumbItem>
                        <Link to={`/ `}>Home</Link>
                      </BreadcrumbItem>
                      <BreadcrumbDivider>/</BreadcrumbDivider>
                      <BreadcrumbItem>
                        <Link to={`/builder/${data.builder.handle}`}>
                          {data.builder.name}
                        </Link>
                      </BreadcrumbItem>
                      <BreadcrumbDivider>/</BreadcrumbDivider>
                      <BreadcrumbItem>
                        <span className="active">{data.model}</span>
                      </BreadcrumbItem>
                    </Col>
                  </Row>
                </Container>
              </Breadcrumb>
            </Col>

            <Col md={6}>
              <Back>
                <Right>
                  <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
                    <span className="icon" style={{ display: 'flex', alignItems: 'center' }}>
                      <IconPageback />
                    </span>
                    <span>Back to listing</span>
                  </Link>
                </Right>
              </Back>
            </Col>
          </Row>

        </Container>

      </Top>

      <Container>
        <Row>
          <Col>
            <InformationCard>
              <div className="boat-name">
                <div className="builder">{data.builder.name}</div>
                <div className="model">{data.model}</div>
              </div>
              <div className="info">
                <div className="item">
                  <div className="subtitle">Price</div>
                  <div className="value">
                    <span className="icon"><IconPrice /></span>
                    {formatCurrency(data.price, data.currency)}
                  </div>
                </div>
                <div className="item">
                  <div className="subtitle">Location</div>
                  <div className="value">
                    <span className="icon"><IconLocation /></span>
                    {data.location}
                  </div>
                </div>
              </div>
              <div className="button">
                <Button
                  dark text="Get in touch" onClick={() => {
                    window.scrollTo(0, 10000)
                  }}
                  iconRight={<IconArrorRight />}
                />
              </div>
            </InformationCard>
          </Col>
        </Row>
      </Container>

      <GalleryWrapper>
        <Container>
          <Row>
            <Col>
              <ImageGallery
                items={gallery}
                showFullscreenButton
                showPlayButton={false}
              />
            </Col>
          </Row>
        </Container>
      </GalleryWrapper>

      {
        (data.videoLink1 || data.videoLink2) && (
          <VideoWrapper>
            <Container>
              <Row>
                <Col>
                  <Title>VIDEO</Title>
                </Col>
              </Row>
              <Row>
                {data.videoLink1 && (
                  <React.Fragment>
                    {!data.videoLink2 && <Col md="3"></Col>}
                    <Col md="6">
                      <iframe
                        width="100%"
                        height="315"
                        src="https://www.youtube.com/embed/L5SUo8qQG_I"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </Col>
                  </React.Fragment>
                )}
                {data.videoLink2 && (
                  <Col md="6">
                    <iframe
                      width="100%"
                      height="315"
                      src="https://www.youtube.com/embed/L5SUo8qQG_I"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </Col>
                )}
              </Row>
            </Container>
          </VideoWrapper>
        )
      }

      <ModelHeader>
        <Container>
          <Row>
            <Col>
              <h1 style={{ fontSize: 28, fontWeight: 600, textAlign: "center" }}>
                {data.built} {data.builder.name} {data.model}
              </h1>
            </Col>
          </Row>
        </Container>
      </ModelHeader>

      <ModelContent>
        <Container>
          <Row>
            <Col>
              <div
                style={{ fontSize: 14, lineHeight: 1.3 }}
                dangerouslySetInnerHTML={{ __html: data.description }}
              ></div>
            </Col>
          </Row>
        </Container>
      </ModelContent>

      <Specifications>
        <Container>
          <Row>
            <Col lg={4}>

              <SpecCardIcon inverse>
                <div className="top">
                  <div className="icon"><IconBuilder /></div>
                  <div className="extra">
                    <Link to={`/builder/${data.builder.handle}`}>
                      See all {data.builder.name}
                    </Link>
                  </div>
                </div>
                <div className="bottom">
                  <div className="title desktop">Builder</div>
                  <div className="text">{data.builder.name}</div>

                  <div className="extra-mobile">
                    <Link to={`/builder/${data.builder.handle}`}>
                      See all {data.builder.name}
                    </Link>
                  </div>
                </div>
              </SpecCardIcon>

            </Col>
            <Col lg={4}>

              <SpecCardIcon>
                <div className="top">
                  <div className="icon"><IconBoat /></div>
                </div>
                <div className="bottom">
                  <div className="title">Model</div>
                  <div className="text">{data.model}</div>
                </div>
              </SpecCardIcon>

            </Col>
            <Col lg={2}>

              <SpecCardIcon>
                <div className="top">
                  <div className="icon"><IconCalendar /></div>
                </div>
                <div className="bottom">
                  <div className="title">Year</div>
                  <div className="text">{data.built}</div>
                </div>
              </SpecCardIcon>

            </Col>
            <Col lg={2}>

              <SpecCardIcon>
                <div className="top">
                  <div className="icon"><IconRuler /> </div>
                </div>
                <div className="bottom">
                  <div className="title">Length</div>
                  <div className="text">{data.loa}</div>
                </div>
              </SpecCardIcon>
            </Col>

            {data.engine && (
              <Col lg={4}>
                <SpecCard>
                  <div className="title">Engine</div>
                  <div className="text">{data.engine}</div>
                </SpecCard>
              </Col>
            )}

            {data.engineType && (
              <Col lg={2} >
                <SpecCard>
                  <div className="title">Engine Type</div>
                  <div className="text">{data.engineType}</div>
                </SpecCard>
              </Col>
            )}

            {data.enginePower && (
              <Col lg={2} >
                <SpecCard>
                  <div className="title">Engine Power</div>
                  <div className="text">{data.enginePower}</div>
                </SpecCard>
              </Col>
            )}

            {data.engineHour && (
              <Col lg={2}>
                <SpecCard>
                  <div className="title">Engine Hours</div>
                  <div className="text">{data.engineHour}</div>
                </SpecCard>
              </Col>
            )}

            {data.topSpeed && (
              <Col lg={2}>
                <SpecCard>
                  <div className="title">Top Speed</div>
                  <div className="text">{data.topSpeed}</div>
                </SpecCard>
              </Col>
            )}
            {data.cruisingSpeed && (
              <Col lg={2}>
                <SpecCard>
                  <div className="title">Cruising Speed</div>
                  <div className="text">{data.cruisingSpeed}</div>
                </SpecCard>
              </Col>
            )}
            {data.hull && (
              <Col lg={2} >
                <SpecCard>
                  <div className="title">Hull</div>
                  <div className="text">{data.hull}</div>
                </SpecCard>
              </Col>
            )}
            {data.cabins && (
              <Col lg={2} >
                <SpecCard>
                  <div className="title">Cabin</div>
                  <div className="text">{data.cabins}</div>
                </SpecCard>
              </Col>
            )}
            {data.guest && (
              <Col lg={2} >
                <SpecCard>
                  <div className="title">Guest</div>
                  <div className="text">{data.guest}</div>
                </SpecCard>
              </Col>
            )}
            {data.beam && (
              <Col lg={2}>
                <SpecCard>
                  <div className="title">Beam</div>
                  <div className="text">{data.beam}</div>
                </SpecCard>
              </Col>
            )}
            {data.draft && (
              <Col lg={2} >
                <SpecCard>
                  <div className="title">Draft</div>
                  <div className="text">{data.draft}</div>
                </SpecCard>
              </Col>
            )}
            {data.flag && (
              <Col lg={2} >
                <SpecCard>
                  <div className="title">Flag</div>
                  <div className="text">USA</div>
                </SpecCard>
              </Col>
            )}

            {data.hullID && (
              <Col lg={2} >
                <SpecCard>
                  <div className="title">Hull ID</div>
                  <div className="text">{data.hullID}</div>
                </SpecCard>
              </Col>
            )}

            {data.stockNumber && (
              <Col lg={2} >
                <SpecCard>
                  <div className="title">Stock Number</div>
                  <div className="text">{data.stockNumber}</div>
                </SpecCard>
              </Col>
            )}
            {data.fuelTankCapacity && (
              <Col lg={2} >
                <SpecCard>
                  <div className="title">Fuel Tank Capacity</div>
                  <div className="text">{data.fuelTankCapacity}</div>
                </SpecCard>
              </Col>
            )}
            {data.fuelType && (
              <Col lg={2} >
                <SpecCard>
                  <div className="title">Fuel Type</div>
                  <div className="text">{data.fuelType}</div>
                </SpecCard>
              </Col>
            )}
            {data.dryWeight && (
              <Col lg={2} >
                <SpecCard>
                  <div className="title">Dry Weight</div>
                  <div className="text">{data.dryWeight}</div>
                </SpecCard>
              </Col>
            )}
          </Row>
        </Container>
      </Specifications>

      <Center style={{ marginTop: 48 }}>
        <Button
          dark
          big
          text="Dowload Brochure"
          iconRight={<IconDownload />}
          onClick={() =>
            window.open(`${config.apiURL}/boats/${data.handle}/pdf`)
          }
        />
      </Center >

      <BrokerWrapper>
        <Container>
          <Row>

            <Col lg="7">
              <ContactCard>
                <div className="title">
                  Contact with us for
                </div>
                <div className="name">
                  {data.builder.name} {data.model} {data.built}.
                </div>
                <div className="form">
                  <form
                    style={{ marginTop: 25 }}
                    onSubmit={onContactSend}
                    autoComplete="off"
                  >
                    <Row>
                      <Col md="6" style={{ marginBottom: 15 }}>
                        <TextBox
                          noLabel
                          fullWidth
                          placeholder="First Name"
                          value={contactForm.firstName}
                          onChange={(v) => setContactFormField("firstName", v)}
                        />
                      </Col>
                      <Col md="6" style={{ marginBottom: 15 }}>
                        <TextBox
                          noLabel
                          required
                          fullWidth
                          placeholder="Last Name"
                          value={contactForm.lastName}
                          onChange={(v) => setContactFormField("lastName", v)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" style={{ marginBottom: 15 }}>
                        <TextBox
                          noLabel
                          fullWidth
                          placeholder="Phone"
                          value={contactForm.phone}
                          onChange={(v) => setContactFormField("phone", v)}
                        />
                      </Col>
                      <Col md="6" style={{ marginBottom: 15 }}>
                        <TextBox
                          noLabel
                          required
                          fullWidth
                          type="email"
                          placeholder="Email"
                          value={contactForm.email}
                          onChange={(v) => setContactFormField("email", v)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{ marginBottom: 15 }}>
                        <TextBox
                          noLabel
                          multiline
                          fullWidth
                          placeholder="Message"
                          value={contactForm.message}
                          onChange={(v) => setContactFormField("message", v)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="9" style={{ color: "#0b0b0b" }}>
                        {message && <div>✅ {message}</div>}
                      </Col>
                      <Col
                        md="3"
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          submit
                          dark
                          big
                          text="SEND"
                          disabled={disableButton}
                        />
                      </Col>
                    </Row>
                  </form>
                </div>
              </ContactCard>
            </Col>

            {data.broker && (
              <Col lg="5">
                <BrokerCard
                  {...data.broker}
                  model={`${data.builder.name} ${data.model} ${data.built}`}
                  link={window.location}
                />
              </Col>
            )}
          </Row>
        </Container>
      </BrokerWrapper>

    </PageWrapper >
  );
};
