export const ListButtonIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23.999" viewBox="0 0 24 23.999">
    <g id="list" transform="translate(-8.425 -8.425)">
      <path id="Path_13" data-name="Path 13"
        d="M8.429,13.131h0a.839.839,0,0,0,.831.838H31.585a.839.839,0,0,0,.835-.839h0V9.263a.839.839,0,0,0-.839-.838H9.264a.839.839,0,0,0-.839.839c0,.014,0,.028,0,.043v3.824Z"
        fill={props.selected ? "#174fe9" : "#8b8b8b"} />
      <path id="Path_14" data-name="Path 14" d="M31.585,20.4H9.264a.839.839,0,0,0-.839.839c0,.014,0,.028,0,.043v3.824h0a.839.839,0,0,0,.831.838H31.586a.839.839,0,0,0,.835-.839h0V21.24A.84.84,0,0,0,31.585,20.4Z" transform="translate(0 -2.749)"
        fill={props.selected ? "#174fe9" : "#8b8b8b"}
      />
      <path id="Path_15" data-name="Path 15" d="M31.585,32.378H9.264a.839.839,0,0,0-.839.839c0,.014,0,.028,0,.043v3.824h0a.839.839,0,0,0,.831.838H31.586a.839.839,0,0,0,.835-.839h0V33.215A.84.84,0,0,0,31.585,32.378Z" transform="translate(0 -5.499)"
        fill={props.selected ? "#174fe9" : "#8b8b8b"}
      />
    </g>
  </svg>
);
