import styled from 'styled-components';

export const Left = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const Center = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Right = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
