import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { TextBox } from "../form/textbox";
import { Dropdown } from "../form/dropdown";
import { Button } from "../form/button";
import BrokerageApi from "../../services/api.service";
import {
  jsonToQueryString,
  queryStringToJson,
} from "../../services/store.service";
import { IconArrowCircleRecyle, IconSearch } from "../Icons";

export const Right = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 20px;

  @media(max-width: 768px) {
    justify-content: space-between;
  }
`

const FilterWrapper = styled.div`
  padding-top: 20px;
  padding-bottom: 0px;
  position: sticky;
  top: -115px;
  left: 0;
  right: 0;
  background: #F5F5F5;
  z-index: 1000;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;

  .tomb__textbox {
    margin-bottom: 20px;
  }

  &.open {
    top: 0;
  }

  @media (max-width: 992px) {
    top: -400px;
    position: relative;
  }
`;

const FormWrapper = styled.div`
`;


export const Filter = () => {
  const [loading, setLoading] = React.useState(true);

  const [filter, setFilter] = React.useState({
    builder: "",
    location_eq: "",
    boatType: "",
    length_from: "",
    length_to: "",
    price_from: "",
    price_to: "",
    year_from: "",
    year_to: "",
  });

  const [open, setOpen] = React.useState(true);
  const [builders, setBuilders] = React.useState([]);
  const [locations, setLocations] = React.useState([]);

  const onFilter = (key, value) => {
    const _filter = { ...filter };
    _filter[key] = value;
    setFilter(_filter);
  };

  const onReset = () => {
    window.location = window.location.pathname;
  };

  const onSearch = async () => {
    const query = jsonToQueryString(filter);
    window.location = window.location.pathname + query;
  };

  const init = async () => {
    const builderList = await BrokerageApi.getBuilders();
    const locationList = await BrokerageApi.getLocations();

    if (builderList.success) {
      const builderResult = [{ value: "", label: "All" }, ...builderList.data.map(x => ({ value: x, label: x }))]
      setBuilders(builderResult);

      const locationResult = [{ value: "", label: "All" }, ...locationList.data.map(x => ({ value: x, label: x }))]
      setLocations(locationResult);

      var initialFilter = queryStringToJson(window.location.search);
      setFilter(initialFilter);
    }

    setLoading(false);
  };

  React.useEffect(() => {
    init();
  }, [window.location]);

  if (loading) return <></>;

  return (
    <FilterWrapper className={`${open ? "open" : ""}`}>
      <FormWrapper>
        <Container>
          <Row>

            <Col xl="2" lg="6">
              <Dropdown
                fullWidth
                label="Brands"
                options={builders}
                onChange={(v) => onFilter("builder", v)}
                value={filter.builder}
              />
            </Col>

            <Col xl="2" lg="6">
              <Dropdown
                fullWidth
                label="Location"
                options={locations}
                onChange={(v) => onFilter("location_eq", v)}
                value={filter.location_eq}
              />
            </Col>

            <Col xl="3" lg="6">
              <Row>
                <Col xs="6">
                  <TextBox
                    label="Price"
                    suffix="$"
                    fullWidth
                    placeholder="From"
                    onChange={(v) => onFilter("price_from", v)}
                    value={filter.price_from}
                  />
                </Col>
                <Col xs="6">
                  <TextBox
                    fullWidth
                    suffix="$"
                    placeholder="To"
                    onChange={(v) => onFilter("price_to", v)}
                    value={filter.price_to}
                  />
                </Col>
              </Row>
            </Col>

            <Col xl="2" lg="6">
              <Row>
                <Col xs="6">
                  <TextBox
                    suffix="ft"
                    label="Length"
                    fullWidth
                    placeholder="from"
                    onChange={(v) => onFilter("length_from", v)}
                    value={filter.length_from}
                  />
                </Col>
                <Col xs="6">
                  <TextBox
                    suffix="ft"
                    fullWidth
                    placeholder="to"
                    onChange={(v) => onFilter("length_to", v)}
                    value={filter.length_to}
                  />
                </Col>
              </Row>
            </Col>

            <Col xl="2" lg="6">
              <Row>
                <Col xs="6">
                  <TextBox
                    label="Year"
                    fullWidth
                    placeholder="From"
                    onChange={(v) => onFilter("year_from", v)}
                    value={filter.year_from}
                  />
                </Col>
                <Col xs="6">
                  <TextBox
                    fullWidth
                    placeholder="To"
                    onChange={(v) => onFilter("year_to", v)}
                    value={filter.year_to}
                  />
                </Col>
              </Row>
            </Col>

            <Col xl="1" lg="12">
              <Right>
                <Button dark text="Search" icon={<IconSearch />} onClick={onSearch} />
                {/*
                <Button text="Reset" icon={<IconArrowCircleRecyle />} onClick={onReset} />
                */}
              </Right>
            </Col>

          </Row>
        </Container>
      </FormWrapper>

    </FilterWrapper>
  );
};
